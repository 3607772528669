import React, { useState, useEffect } from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function MultiSelectFilterTabWithoutAll({
  tabs,
  onChange,
}: {
  tabs: any;
  onChange: any;
}) {
  const [selectedTabs, setSelectedTabs] = useState<any>("Project");

  const toggleTab = (tab: any) => {
    setSelectedTabs(tab);
  };

  useEffect(() => {
    onChange(selectedTabs);
  }, [selectedTabs, onChange]);

  function formatLabel(label:string) {
    return label
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/\b\w/g, char => char.toUpperCase())
      .toUpperCase(); 
  }
  
  return (
    <div className="flex justify-end">
      <nav aria-label="Tabs" className="inline-flex" role="group">
        {[...tabs].map((tab: any, index: any) => (
          <button
            onClick={() => toggleTab(tab)}
            key={tab}
            className={classNames(
              "px-4 py-2 text-xs tracking-wide whitespace-nowrap font-semibold border border-gray-300",
              "transition-all duration-200 ease-in-out",
              "",
              selectedTabs.includes(tab)
                ? "border bg-blue-600 text-white font-semibold"
                : "bg-white text-gray-700 hover:bg-gray-100",
              " shadow-box",
              index === 0 ? "rounded-l-md" : "",
              index === tabs.length - 1 ? "rounded-r-md" : "",
              index !== 0 ? "-ml-px" : ""
            )}
          >
            {formatLabel(tab)}
          </button>
        ))}
      </nav>
    </div>
  );
}
